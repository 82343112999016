.navbar {
    position: absolute;
    width: 100%;
    z-index: 100;
}

.navbar_Content {
    padding: 2rem;
    align-items: flex-start;
    justify-content: flex-start;
}

.navbar_Logo {
    width: 20%;
}
