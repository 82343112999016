.services {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FEFEFE;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover; 
    background-image: url('/public/images/our-services.png'); 
    background-position: center; 
}

.services_Content {
    padding: 4rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}


.services_Content > h1 {
    font-family: 'Baskervville', serif;
    font-weight: 300;
    text-align: left;
    font-size: 3rem;
    color: #FFFFFF;
}

.services_Content_Yellow {
    color: #F1A024;
    font-style: italic;
}

.services_Cards {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
}

.services_Card {
    padding: 2rem;
    font-family: 'Gudea', sans-serif;
    width: 35%;
    border-left: 5px solid #F1A024;
    background-color: #FFFFFF;
    color: #111;
    text-align: left;
    height: 300px;
}

.services_Card > h1 {
    font-family: 'Baskervville', serif;
    font-weight: 300;
    width: 100%;
    text-align: left;
    font-size: 2rem;
    margin: 0px;
    color: #111;
}


.checkmark {
    display: inline-block;
    width: 4px;
    height: 6px;
    margin-right: 8px;
    border-right: 1px solid #F1A024;
    border-bottom: 1px solid #F1A024;
    transform: rotate(45deg);
  }
  
  @media (max-width: 768px) {
    .services_Cards {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .services_Card {
        width: 100%;
    }
  }