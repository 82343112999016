.about {
    display: flex;
    font-family: 'Gudea', sans-serif;
}

.about_Content {
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about_Image > img {
    width: 300px;
    margin-top: -8rem;
    padding-right: 2rem;
}

.about_Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
}

.about_Text_Yellow {
    color: #F1A024;
    font-style: italic;
}

.about_Text > h1 {
    font-family: 'Baskervville', serif;
    font-weight: 300;
    font-size: 3rem;
    margin: 0px;
    color: #111;
}

.about_Text > p {
    font-family: 'Baskervville', serif;
    font-weight: 300;
    color: #111;
}

.about_Text > h5 {
    font-weight: 300;
}

@media (max-width: 768px) {
    .about_Content {
        display: flex;
        flex-direction: column;
    }

    .about_Text {
        margin-top: 4rem;
    }
}