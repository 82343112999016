.ourvision {
    display: flex;
    font-family: 'Gudea', sans-serif;
}

.ourvision_Content {
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ourvision_Header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.ourvision_Header > h1 {
    font-family: 'Baskervville', serif;
    font-weight: 300;
    width: 40%;
    text-align: left;
    font-size: 3rem;
    margin: 0px;
    color: #111;
}

.ourvision_Header_Yellow {
    color: #F1A024;
    font-style: italic;
}


.ourvision_Bar {
    background-color: #F1A024;
    height: 0.1rem;
    width: 100%;
}

.ourvision_Texts {
    display: flex;
    gap: 1rem;
    text-align: left;
    margin-top: 2rem;
}

.ourvision_Texts > p {
    font-weight: 300;
    font-size: 1rem;
    width: 50%;
    font-size: 1rem;
    font-family: 'Baskervville', serif;
}

.ourvision_Texts > h5 {
    font-weight: 300;
    font-size: 0.8rem;
    width: 50%;
}

.ourvision_Card {
    background-color: #111;
    margin-top: 2rem;
    color: #FEFEFE;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover; 
    background-image: url('/public/images/ourvision.png'); 
    background-position: center; 
}

@media (max-width: 768px) {
    .ourvision_Texts {
        display: flex;
        flex-direction: column;
    }

    .ourvision_Texts > p {
        width: 100%;
    }

    .ourvision_Texts > h5 {
        width: 100%;
    }
}
