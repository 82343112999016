.footer {
    display: flex;
    color: #FEFEFE;
}

.footer_Content {
    background-color: #2A2E36;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 4rem;
}

.footer_Top {
    padding: 2rem;
    align-items: flex-start;
    justify-content: space-around;
    display: flex;
    text-align: left;
}

.footer_Bottom {
    border-top: 1px solid #FEFEFE;
    padding: 2rem;
    align-items: center;
    justify-content: space-between;
    display: flex;
    font-weight: 100;
    font-size: 0.8rem;
}

.footer_Contact > p {
    font-family: 'Baskervville', serif;
    font-weight: 300;
    text-align: left;
    font-style: italic;
}

.italic_Test {
    font-style: italic;
    color: #F1A024;
}

@media (max-width: 768px) {
    .footer_Top {
        flex-direction: column;
    }
}