.ourmission {
    display: flex;
    font-family: 'Gudea', sans-serif;
}

.ourmission_Content {
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ourmission_Header {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

.ourmission_Header > h1 {
    font-family: 'Baskervville', serif;
    font-weight: 300;
    width: 40%;
    text-align: right;
    font-size: 3rem;
    margin: 0px;
    color: #111;
}

.ourmission_Header_Yellow {
    color: #F1A024;
    font-style: italic;
}

.ourmission_Bar {
    background-color: #F1A024;
    height: 0.1rem;
    width: 100%;
}

.ourmission_Texts {
    display: flex;
    gap: 1rem;
    text-align: left;
    margin-top: 2rem;
    
}

.ourmission_Texts > p {
    font-weight: 300;
    font-size: 1rem;
    width: 50%;
    font-family: 'Baskervville', serif;
}

.ourmission_Texts > h5 {
    font-weight: 300;
    font-size: 0.8rem;
    width: 50%;
}


@media (max-width: 768px) {
    .ourmission_Texts {
        display: flex;
        flex-direction: column;
    }

    .ourmission_Texts > p {
        width: 100%;
    }

    .ourmission_Texts > h5 {
        width: 100%;
    }
}
