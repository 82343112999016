@import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gudea&display=swap');


.hero {
    background-color: #111;
    color: #FEFEFE;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover; 
    background-image: url('/public/images/background.png'); 
    background-position: center; 
    height: 100vh;
}

.hero_Content {
    display: flex;
    align-items: center;
    padding: 2rem;
    font-family: 'Gudea', sans-serif;
    flex-direction: column;
}


.hero_Bar {
    height: 0.1rem;
    width: 10%;
    background-color: #FEFEFE;
}
.hero_Content > h1 {
    font-family: 'Baskervville', serif;
    font-weight: 300;
    font-size: 3rem;
    margin: 0px;
    color: #FEFEFE;
}

.hero_Content > h2 {
    font-family: 'Baskervville', serif;
    font-weight: 300;
    margin: 0px;
    font-size: 3rem;
    font-style: italic;
    color: #F1A024;
}

.hero_Content > p {
    font-weight: 500;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    letter-spacing: 0.3rem;
    color: #F1A024;
}



